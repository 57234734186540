import React from "react"

import "prismjs/themes/prism-solarizedlight.css"
import "katex/dist/katex.min.css"
import { AnimateSharedLayout } from "framer-motion"

export const wrapPageElement = ({ element, props }) => {
    return <AnimateSharedLayout {...props}>
        {element}
    </AnimateSharedLayout>
}
